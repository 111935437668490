import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faSpotify,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Parallax from "components/Parallax";
import javelin from "assets/javelin.gif";
import moshed5 from "assets/moshed/5.gif";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Parallax bg={moshed5}>
        <h2>IVAN AND PETER</h2>
        <h3>VIRTUAL DJS FROM THE FUTURE</h3>
      </Parallax>
      <Parallax bg={javelin}>
        <a href="https://found.ee/djflulareggiewattsivanandpeter_javelinmoveyourbody">
          <h2 className="bright">LISTEN NOW</h2>
        </a>
      </Parallax>
      <Parallax bg={moshed5}>
        <h2 className="lil-guy">WE COME IN PEACE</h2>
        <div className="icon-container">
          <a
            href="https://www.tiktok.com/@ivan_and_peter?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={faTiktok} />
          </a>
          <a
            href="https://twitter.com/IvanAndPeter"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={faTwitter} />
          </a>
          <a
            href="https://www.instagram.com/ivanandpeter"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={faInstagram} />
          </a>
          <a
            href="https://open.spotify.com/artist/5jFepnWLPWhkMKz2GMDu38"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={faSpotify} />
          </a>
        </div>
      </Parallax>
      <footer>&copy; Hume 2021</footer>
    </div>
  );
}

export default App;
