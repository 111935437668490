function Parallax({ bg: backgroundImage, children }) {
  return (
    <div className="parallax-outer" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="parallax-inner">
        { children }
      </div>
    </div>
  )
}

export default Parallax;
